import { useCallback, useContext } from "react";
import { SnackbarContext } from "../context/Snackbar/SnackbarContext";
import guests from "./guests.json";

export function CheckRSVP(target) {
  const partners = ["Suami", "Istri", "Partner"];
  const { list } = guests;

  const findGuest = list.find((rsvp) => rsvp.name === target);
  const guestPartner = findGuest?.partner;
  let guest = { name: "", partner: null, rsvp: false };

  if (findGuest) {
    guest = {...findGuest, partner: guestPartner !== null ? partners[guestPartner] : guestPartner};
  };
  
  return guest;
}

export const useNotif = () => {
  const { setSnackbar } = useContext(SnackbarContext);
  return {
    notif: useCallback((msg) => {
      if (typeof msg === "string") setSnackbar(msg);
      // else notifyError(msg, setSnackbar);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  };
};
