import React from "react";
import PropTypes from "prop-types";
import styles from "./Pembuka.module.scss";
import { Box, Container } from "@mui/material";
import { useTransition, animated } from "react-spring";
import { ButtonMain } from "../../Form";
import { ReactComponent as Flower } from "../../../assets/bunga/flower_1.svg";
import { useSelector } from "react-redux";

function Component({ openRSVPstate, params }) {
  const { openRSVP, handleRSVP } = openRSVPstate;
  const { name: guestName, partner: guestPartner } = params;
  const { title } = useSelector(state => state.details);

  const transition = useTransition(openRSVP, {
    from: { y: -1000 },
    enter: { y: 0 },
    leave: { y: -1000 },
    config: {
      duration: 500,
    },
  });

  return (
    <>
      {transition((style, item) =>
        item ? (
          <animated.div style={style} className={styles.pembuka__wrapper}>
            <Container maxWidth="lg" style={{ height: "100%" }}>
              <Box
                className={
                  guestName
                    ? styles.pembuka__container
                    : styles.pembuka__container__nonrsvp
                }
              >
                <Box className={styles._title}>
                  <Box className={styles._title__content}>
                    <Flower
                      className={styles._bunga}
                      data-aos={"zoom-in"}
                      data-aos-delay={500}
                    />
                    <span
                      className={`${styles._title__body} text-body`}
                      data-aos={"fade-up"}
                      data-aos-delay={600}
                    >
                      Pernikahan
                    </span>
                    <span
                      className={`${styles._title__name} header-3 mb-8`}
                      data-aos={"fade-up"}
                      data-aos-delay={700}
                    >
                      {title}
                    </span>
                    {!guestName ? (
                      <span
                        className={`${styles._title__date} text-body`}
                        data-aos={"fade-up"}
                        data-aos-delay={800}
                      >
                        23 Juli 2022
                      </span>
                    ) : null}
                  </Box>
                </Box>

                {guestName ? (
                  <Box className={styles._invited__container}>
                    <Box
                      className="text-body"
                      data-aos="fade-up"
                      data-aos-delay="800"
                    >
                      Hello,
                    </Box>
                    <Box className={styles.pembuka__invited__content}>
                      <Box
                        className={styles.pembuka__invited__name}
                        data-aos="fade-up"
                        data-aos-delay="900"
                      >
                        {guestName}
                      </Box>
                      {guestPartner && (
                        <Box data-aos="fade-up" data-aos-delay="1000">
                          dan {guestPartner}
                        </Box>
                      )}
                    </Box>
                    <Box
                      className="text-body"
                      data-aos="fade-up"
                      data-aos-delay="1100"
                    >
                      Kami mengundang Anda untuk merayakan pernikahan kami.
                    </Box>
                  </Box>
                ) : null}

                <Box
                  data-aos={"fade-up"}
                  data-aos-delay={guestName ? 1200 : 900}
                >
                  <ButtonMain onClick={handleRSVP}>Buka Undangan</ButtonMain>
                </Box>
              </Box>
            </Container>
          </animated.div>
        ) : (
          ""
        )
      )}
    </>
  );
}

Component.propTypes = {
  params: PropTypes.object,
};

export default Component;
