import axios from "axios";

export const getDimension = () => {
  return async (dispatch) => {
    try {
      const { innerWidth } = window;
      let isTablet = innerWidth < 769 ? true : false;

      await dispatch({ type: "DIMENSION", payload: isTablet });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPrayers = (page, setLoading) => {
  return async (dispatch) => {
    const URL_API = process.env.REACT_APP_URL_API;
    const ID = process.env.REACT_APP_CLIENT_ID;

    axios
      .get(`${URL_API}/event/${ID}/pesan-harapan?page=${page}&per_page=6`)
      .then((response) => {
        const { data, ...pagination } = response.data.data;
        dispatch({ type: "PRAYERS", payload: data });
        dispatch({ type: "PAGINATION", payload: pagination });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getDetail = (setLoading) => {
  return async (dispatch) => {
    const URL_API = process.env.REACT_APP_URL_API;
    const ID = process.env.REACT_APP_CLIENT_ID;
    setLoading(true);

    axios
      .get(`${URL_API}/event/${ID}`)
      .then((response) => {
        dispatch({type: 'DETAILS', payload: response.data.data})
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
};
